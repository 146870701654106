class Insulin {
    constructor() {
        this.form = document.getElementById("jsCalcInsulin");

        if (this.form) {
            this.type = document.querySelector(".jsInsulinType");
            this.typeValue = this.type.value;
            this.rowAll = document.querySelector(".jsInsulinAll");
            this.rowDay = document.querySelector(".jsInsulinDay");
            this.countAll = document.querySelector(".jsInsulinCountAll");
            this.count1 = document.querySelector(".jsInsulinCount1");
            this.count2 = document.querySelector(".jsInsulinCount2");
            this.count3 = document.querySelector(".jsInsulinCount3");
            this.results = document.querySelector(".jsInsulinResults");

            this.setTypeValue();
            this.events();
        }
    }

    events() {
        let self = this;
        this.type.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.countAll.addEventListener("input", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.count1.addEventListener("input", function () {
            self.calculate();
        });

        this.count2.addEventListener("input", function () {
            self.calculate();
        });

        this.count3.addEventListener("input", function () {
            self.calculate();
        });
    }

    setTypeValue() {
        this.typeValue = this.type.value;
        if (this.typeValue == 'all') {
            this.rowAll.style.display = 'flex';
            this.rowDay.style.display = 'none';
        } else {
            this.rowAll.style.display = 'none';
            this.rowDay.style.display = 'flex';
        }
    }

    calculate() {
        let calculation = 0;

        if (this.validation()) {
            if (this.typeValue == 'all') {
                calculation = (this.countAll.value * 180) / 300;
            } else {
                calculation = ((parseInt(this.count1.value) + parseInt(this.count2.value) + parseInt(this.count3.value)) * 180) / 300;
            }
            calculation = Math.round(calculation);
            this.results.innerHTML = `Przy wskazanym dawkowaniu na 180 dni terapii możesz wydać ${calculation} fiolki insuliny o pojemności 3 ml i aktywności 100 j.m./ml.`;
        }
    }

    validation() {
        let validate = false;
        let resultsRow = document.querySelector(".form__row--results");
        resultsRow.style.display = "none";

        if (this.typeValue == 'all') {
            if (this.countAll.value) {
                validate = true;
            }
        } else {
            if (this.count1.value && this.count2.value && this.count3.value) {
                validate = true;
            }
        }

        if (validate) {
            resultsRow.style.display = "block";
        }

        return validate;
    }
}

export default Insulin;