class Milk {
    constructor() {
        this.form = document.getElementById("jsCalcMilk");

        if (this.form) {
            this.type = document.querySelector(".jsMilkType");
            this.typeValue = this.type.value;
            this.portion = document.querySelector(".jsMilkPortion");
            this.dose = document.querySelector(".jsMilkDose");
            this.results = document.querySelector(".jsMilkResults");

            this.events();
        }
    }

    events() {
        let self = this;
        this.type.addEventListener("change", function() {
            self.setTypeValue();
            self.calculate();
        });

        this.portion.addEventListener("input", function() {
            self.calculate();
        });

        this.dose.addEventListener("input", function() {
            self.calculate();
        });
    }

    setTypeValue() {
        this.typeValue = this.type.value;
    }

    calculate() {
        let portionValue = this.portion.value;
        let doseValue = this.dose.value;
        let calculation = 0;

        if(this.validation()) {
            calculation = 13.5 * doseValue / 100  * portionValue * 180 / this.typeValue;
            calculation = calculation.toFixed(2);
            this.results.innerHTML = `Na jedną receptę (180 dni) możesz wydać ${calculation} opakowań mleka.`;
        }
    }

    validation() {
        let portionValue = this.portion.value;
        let doseValue = this.dose.value;
        let validate = false;
        let resultsRow = document.querySelector(".form__row--results");
        resultsRow.style.display = "none";

        if ((portionValue && portionValue != 0) && (doseValue && doseValue != 0)) {
            validate = true;
            resultsRow.style.display = "block";
        }

        return validate;
    }
}

export default Milk;