
class VitaminAD3 {
    constructor() {
        this.form = document.getElementById("jsCalcVitaminAD3");

        if (this.form) {
            this.vitaminUnit = document.querySelector(".jsVitaminAD3Unit");
            this.vitaminJmAlert = document.querySelector(".jsVitaminAD3JmAlert");
            this.vitaminUnitValue = this.vitaminUnit.value;
            this.vitaminQuantity = document.querySelector(".jsVitaminAD3Quantity");
            this.result = document.querySelector(".jsCalcVitaminAD3Need");

            this.setTypeValue();
            this.events();
        }
    }

    events() {
        let self = this;
        this.vitaminUnit.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate()
        });

        this.vitaminQuantity.addEventListener("input", function () {
            self.setTypeValue();
            self.calculate();
        });
    }

    setTypeValue() {
        this.vitaminUnitValue = this.vitaminUnit.value;

        if(this.vitaminUnit.value == "jm") {
            this.vitaminJmAlert.style.display = "block";
        } else {
            this.vitaminJmAlert.style.display = "none";
        }
    }

    calculate() {
        if (this.validation()) {
            let needResult = 0;
            let resultJm = 0;
            let resultG = 0;
            let resultMl = 0;
            let resultDrops = 0;

            switch (this.vitaminUnitValue) {
                case 'jm':
                    console.log('jm');
                    resultMl = (parseFloat(this.vitaminQuantity.value) / 30000).toFixed(2);
                    resultG = (parseFloat(this.vitaminQuantity.value) / 30000 * 1.09).toFixed(2);
                    resultDrops = (parseFloat(this.vitaminQuantity.value) / 34 * 30000).toFixed(2);
                    this.result.innerHTML = `${resultMl} ml <br />`
                        + `${resultG} g (dla średniej gęstości 1,09 g/ml)<br />`
                        + `${resultDrops} (1ml = 34 krople)`;
                    break;
                case 'drops':
                    resultMl = (parseFloat(this.vitaminQuantity.value) / 34).toFixed(2);
                    resultG = (parseFloat(this.vitaminQuantity.value) / 34 * 1.09).toFixed(2);
                    resultJm = (parseFloat(this.vitaminQuantity.value) / 34 * 30000).toFixed(2);
                    this.result.innerHTML = `${resultMl} ml <br />`
                        + `${resultG} g (dla średniej gęstości 1,09 g/ml)<br />`
                        + `${resultJm} (suma jednostek witamin A i D<sub>3</sub>)`;
                    break;
                case 'ml':
                    resultDrops = (parseFloat(this.vitaminQuantity.value) * 34).toFixed(0);
                    resultG = (parseFloat(this.vitaminQuantity.value) * 1.09).toFixed(2);
                    resultJm = (parseFloat(this.vitaminQuantity.value) * 30000).toFixed(2);
                    this.result.innerHTML = `${resultG} g (dla średniej gęstości 1,09 g/ml)<br />`
                        + `${resultDrops} krople (1ml = 34 krople)<br />`
                        + `${resultJm} (suma jednostek witamin A i D<sub>3</sub>)`;
                    break;
                case 'g':
                    resultMl = (parseFloat(this.vitaminQuantity.value) / 1.09).toFixed(2);
                    resultDrops = (parseFloat(this.vitaminQuantity.value) / 1.09 * 34).toFixed(0);
                    resultJm = (parseFloat(this.vitaminQuantity.value) / 1.09 * 30000).toFixed(2);
                    this.result.innerHTML = `${resultMl} ml (dla średniej gęstości 1,09 g/ml)<br />`
                        + `${resultDrops} krople (1ml = 34 krople)<br />`
                        + `${resultJm} (suma jednostek witamin A i D<sub>3</sub>)`;
                    break;
                default:
                    console.log('asd');
            }


            // if (this.vitaminPrescriptionValue == 'g') {
            //     resultVitA = this.vitaminQuantity.value / this.rangeSlider.noUiSlider.get() / 10;
            //     resultVitA = resultVitA.toFixed(2);
            //     this.vitaminNeed.innerHTML = `Do wykonania leku potrzebujesz: ${this.vitaminQuantity.value} g witaminy A`;
            //     this.result.innerHTML = `Wyceniając lek zdejmij ze stanu: ${resultVitA} opakowania witaminy A`;
            // } else {
            //     needResult = (this.vitaminQuantity.value * 1000) / (this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.jm * this.rangeSlider.noUiSlider.get());
            //     needResult = needResult.toFixed(2);
            //     resultVitA = (this.vitaminQuantity.value * 1000) / this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.jm / 10;
            //     resultVitA = resultVitA.toFixed(2);
            //     this.vitaminNeed.innerHTML = `Do wykonania leku potrzebujesz: ${needResult} g witaminy A`;
            //     this.result.innerHTML = `Wyceniając lek zdejmij ze stanu: ${resultVitA} opakowania witaminy A`;
            // }
        }
    }

    validation() {
        let validate = false;
        let resultsRow = document.querySelector(".jsVitaminAD3Results");
        resultsRow.style.display = "none";

        if (this.vitaminUnitValue != 0 && this.vitaminQuantity.value.length > 0) {
            validate = true;
            resultsRow.style.display = "flex";
        }

        return validate;
    }
}

export default VitaminAD3;